import React from "react";
import { Helmet } from "react-helmet";

import favicon from "../images/favicon.ico";
import banner from "../images/fb_banner_koronica.png";

export default props => (
  <Helmet>
    <link rel="icon" href={favicon}/>
    <meta charSet="utf-8"/>
    
    <title>{props.title ? props.title : "Fronta: Korona"}</title>

    <meta name="description" content={props.description ? props.description : "Fronta.org projekt: Analize Korone in orodja proti njej."}/>
    <meta name="keywords" content="Korona, pravice, delavci, najemniki, stanovanje, preskrba"/>
    <meta name="author" content="Fronta"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

    <meta property="og:title" content={props.title}/>
    <meta property="og:description" content={props.description}/>
    <meta property="og:image" content={banner}/>
    <meta property="og:url" content="http://www.korona.fronta.org"/>
  </Helmet>
);
