import React from "react";
import { Link } from "gatsby";
import { Location } from "@reach/router";

import Meta from "./meta.js";

import styles from "./layout.module.css";

console.log(styles);

const navTitles = {
  "analize": "Analize",
  "na_ulice": "Na ulice!",
  "orodjarna": "Orodjarna",
  "pisma": "Pisma",
};

class NavBar extends React.Component {
  genMobiNav() {
    const pathName = this.props.location.pathname;

    let navContent = "";

    const paths = pathName.split("/").filter(sub => (sub !== "")).slice(0, -1);

    if (paths.length > 0) {
      navContent = (
        <span>
          <span>{">"}</span>
          <Link to={pathName}>{this.props.navTitle}</Link>
        </span>
      );
    }

    if (paths.length === 0) {
      navContent = (
        <span>
          {"|"}
          <Link to={pathName}>{this.props.navTitle}</Link>
        </span>
      );
    }

    if (pathName === "/") {
      navContent = "";
    }

    return (
      <span>
        <Link to="/">Preživeti Korono</Link>
        {navContent}
      </span>
    );
  }

  genNav() {
    const pathName = this.props.location.pathname;

    if (pathName === "/") {
      return (
        <span>
          <Link to="/">Preživeti Korono</Link>
        </span>
      );
    }

    const paths = pathName.split("/").filter(sub => (sub !== "")).slice(0, -1);
    let links = [];

    let i = 0;
    while(i < paths.length) {
      const prev = paths.slice(0, i+1);
      const path = prev.join("/");
      links.push("/" + path);
      i++;
    }

    return (
      <span>
        <span><Link to="/">Preživeti Korono</Link></span>
        {paths.map((sub, i) =>
          <span key={sub}>
            {"|"}
            <Link to={links[i]}>
              {navTitles[sub]}
            </Link>
          </span>)}
        {this.props.navTitle ? <span>{"|"}<Link to={pathName}>{this.props.navTitle}</Link></span> : ""}
      </span>
    );
  }

  render() {
    return (
      <div>
        <div id={styles.navbar}>
          {this.genNav()}
        </div>

        <div id={styles.mobiNavbar}>
          {this.genMobiNav()}
        </div>
      </div>
    );
  }
}

class Layout extends React.Component {
  render() {
    return (
      <div id={styles.container}>
        <Meta
          title={this.props.title ? this.props.title : "Preživeti Korono"}
          description={this.props.description ? this.props.description : "Platforma za organiziranje in solidarnost v času Korone in po njej."}
        />

        <Location>
          {locProps => <NavBar navTitle={this.props.navTitle} {...locProps}/>}
        </Location>

        <div id={styles.children}>
          {this.props.children}
        </div>

        <div id={styles.footer}>
          <div id={styles.footerIn}>
            <p>Dizajn: Welmish</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Layout;
